/*import axios from "axios";

const api = axios.create({
  baseURL: "https://baseconverterapi.azurewebsites.net/api/"
});

export default api;*/
import axios from "axios";

const api = axios.create({
  baseURL: "https://baseconverterapi.azurewebsites.net/api/"
});

export default api;
